@media screen and (max-width: 991px) {
  .container-image-box {
    padding: 0;
    .row {
      margin-right: -5px;
      margin-left: -5px;
      /* margin-right: -23px;
      margin-left: -23px; */
    }
  }
  .col-image-box {
    /*   padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 10px;
    padding-right: 0;
    padding-left: 0;
    &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
      padding-right: 10px;
    }
    
    .image-box {
      max-height: 176px;
    }*/
  }

}




@media screen and (min-width: 768px) and (max-width: 991px) {
  .right-fixed-menu {
    bottom: 0;
    left: 0;
    top: auto;
    display: flex;
    width: 100%;
    z-index: 2;
    ul {
      display: inline-flex;
      width: 100%;
      li {
        border-radius: 0;
        margin-right: auto;
        right: auto;
        margin-bottom: 0;
        display: flex;
        padding: 5px 15px 10px 15px;
        width: calc(100% / 6);
        text-align: center;
        border-right: 2px solid white;
        justify-content: center;
        &.active {
          right: 0;
        }
        &:nth-child(1) {
          order: 1;
        }
        &:nth-child(2) {
          order: 2;
        }
        &:nth-child(3) {
          order: 4;
        }
        &:nth-child(4) {
          order: 6;
          border-right: 0;
        }
        &:nth-child(5) {
          order: 3;
        }
        &:nth-child(6) {
          order: 5;
        }
        a {
          line-height: 0;
        }
        i {
          margin-right: 0 !important;
          width: 100%;
          &:after {
            content: "";
            clear: both;
            display: inline-flex;
            width: 100%;
          }
        }
        &:nth-child(2), &:nth-child(5) {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  .container-next-prev__arrows .prev, .container-next-prev__arrows .next {
    font-size: 40px;
  }
  .slick-slider .slick-dots li button:before {
    font-size: 11px;
  }
  .slick-slider .slick-dots {
    padding-right: 40px;
  }
  .slick-slider-item__description {
    padding-left: 35px;
    h2 {
      font-size: 42px;
      line-height: 45px;
    }
    p {
      font-size: 18px;
      line-height: 24px;
    }
  }
  .main-content__mb-text {
    padding: 0 30px;
  }
  /* .container-image-box {
    padding: 0;
    .row {
        margin-right: -5px;
      margin-left: -5px;
    }
  }
  .col-image-box {
      padding-right: 5px;
    padding-left: 5px;
    
    }
    
    .image-box {
      max-height: 176px;
    }*/
}
.right-navigation__top-button {
  width: 100%;
  position: relative;
  justify-content: flex-end;
  margin-bottom: 30px;
  &:after {
    position: absolute;
    content: "";
    display: inline-flex;
    width: 100%;
    clear: both;
  }
}
.right-navigation form {
  top: -3px;
  margin-right: 25px;
}
.right-navigation__form {
  justify-content: flex-end;
  margin-bottom: 0;
}
#copyright {
  width: 100%;
  text-align: right;
  font-size: 12px;
  line-height: 23px;
  color: #7B7A7A;
  /*margin-bottom: 30px;*/
  a {
    color: #7B7A7A;
    &:hover {
      opacity: 0.5;
      color: #7B7A7A;
    }
  }
}
.scroll-top {
  bottom: 80px;
  right: 15px;
}
.page-article-box__img img {
  max-height: 460px;
}
.page-two-bg {
  overflow: hidden;
  img {
    height: 230px;
    width: auto;
  }
}

@media screen and (max-width: 991px) {
  #copyright {
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 414px) {
  #copyright {
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  body {
    overflow-x: hidden;
  }
  .right-fixed-menu {
    ul {
      li {
        &:nth-child(1) {
          order: 1;
          display:none;
        }
        &:nth-child(2) {
          order: 2;
          display:none !important;
        }
        &:nth-child(3) {
          order: 4;
        }
        &:nth-child(4) {
          order: 6;
          border-right: 0;
          display:none;
        }
        &:nth-child(5) {
          order: 3;
        }
        &:nth-child(6) {
          order: 5;
        }
        &.active {
          right: 0;
        }
      }
    }
  }
  .page-article-box {
    flex-wrap: wrap;
  }
  .page-article-box__img {
    padding-right: 0;
  }
  .page-article-box__img img {
    width: 100%;
    height: auto;
  }
  .right-fixed-menu {
    bottom: 0;
    left: 0;
    top: auto;
    display: flex;
    width: 100%;
    z-index: 2;
    ul {
      display: inline-flex;
      width: 100%;
      li {
        border-radius: 0;
        right: auto;
        margin-right: auto;
        margin-bottom: 0;
        display: flex;
        padding: 5px 15px 10px 15px;
        /* width: calc(100% / 6); */
        width: calc(100% / 3);
        text-align: center;
        border-right: 2px solid white;
        justify-content: center;
        a {
          line-height: 0;
        }
        i {
          margin-right: 0 !important;
          width: 100%;
          &:after {
            content: "";
            clear: both;
            display: inline-flex;
            width: 100%;
          }
        }
        &:nth-child(2), &:nth-child(5) {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  .herold-navbar {
    flex-wrap: nowrap;
  }
  header nav .navbar-brand {
    width: 39%;
    padding-top: 25px;
  }
  .right-navigation {
    width: 60%;
  }
  .right-navigation__top-button {
    width: 100%;
    position: relative;
    justify-content: flex-end;
    margin-bottom: 30px;
    &:after {
      position: absolute;
      content: "";
      display: inline-flex;
      width: 100%;
      clear: both;
    }
  }
  .right-navigation form {
    top: -3px;
  }
  .right-navigation__form {
    justify-content: flex-end;
    margin-bottom: 0;
  }
  .slick-slider-item__description {
    padding-left: 10px;
    h2 {
      font-size: 37px;
      line-height: 35px;
    }
    p {
      font-size: 17px;
      line-height: 22px;
    }
  }
  .container-next-prev, .slick-dots {
    display: none;
  }
  .container-image-box {
    padding: 0;
  }
  /* .col-image-box {
    padding-right: 0;
    padding-left: 0;
    &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
      padding-right: 10px;
    }
    .image-box {
      max-height: 126px;
    }
  } */

  .image-box .image-box__title h5 {
    font-size: 16px;
    line-height: 20px;
  }
  .footer-mobile {
    margin-bottom: 20px;
  }
  .scroll-top {
    bottom: 90px;
  }
  .page-two-bg {
    overflow: hidden;
    img {
      height: 230px;
      width: auto;
    }
  }
  footer .footer-menu-mobile {
    display: none;
  }
}

@media screen and (min-width: 415px) and (max-width: 575px) {
  footer .footer-menu-mobile {
    display: block;
  }
  .footer-mobile-3 .footer-menu__i {
    display:none;
  }
  .footer-menu-mobile {
    &__ul {
      text-align: center;
      display: inline-flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    &__li {
      width: 25%;
      margin-bottom: 7px;
      a {
        color: #d37016;
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
      }
      /*&:last-child {
        width: 100%;
        a {
          font-size: 55px;
          font-weight: 400;
          color: black;
          opacity: 0.2;
          margin-top: 10px;
        }
      }*/
    }
  }
  header nav .navbar-brand {
    order: 2;
  }
  .right-navigation {
    order: 1;
    width: 100%;
  }
  .right-navigation__top-button {
    width: 100%;
    position: relative;
    justify-content: center;
    &:after {
      position: absolute;
      content: "";
      display: inline-flex;
      width: 100%;
      clear: both;
    }
  }
  .right-navigation form {
    top: 16px;
  }
  .right-navigation__form {
    justify-content: flex-end;
    margin-left: (-15px) !important;
    &.ml-auto {
      margin: 0 -15px;
    }
  }
  .footer-mobile {
    text-align: center;
    margin-bottom: 20px;
  }
  .herold-navbar {
    flex-wrap: wrap;
  }
  header nav .navbar-brand {
    padding-top: 0;
    width: auto;
    margin-top: -50px;
  }
  .bg-orange-button, .bg-grey-button {
    width: 100%;
  }
  .bg-orange-button {
    flex-grow: 1;
    flex-basis: 40%;
  }
  .bg-grey-button {
    flex-grow: 3;
  }
  .right-navigation__top-button {
    margin-bottom: 0;
  }
  .icon-menu {
    top: 31px;
    margin-right: 15px;
  }
  .slick-slider-item__description {
    padding-left: 15px;
    h2 {
      font-size: 23px;
      line-height: 22px;
      margin-bottom: 5px;
    }
    p {
      font-size: 17px;
      line-height: 21px;
      margin-bottom: 5px;
    }
  }
  .slick-dots {
    display: none !important;
  }
  .container-image-box {
    padding: 0;
    .row {
      margin-right: -5px;
      margin-left: -5px;
    }
  }
  .col-image-box {
    /* padding-right: 0;
    padding-left: 0;
    margin-bottom: 10px; */
    /* &:nth-child(1), &:nth-child(2), &:nth-child(4) {
      padding-right: 10px;
    }
    &:nth-child(3) {
      padding-right: 0;
    }
    .image-box {
      max-height: 126px;
    } */
  }
  .right-fixed-menu {
    bottom: 0;
    left: 0;
    top: auto;
    display: flex;
    width: 100%;
    ul {
      display: inline-flex;
      width: 100%;
      /* li {
        border-radius: 0;
        right: auto;
        margin-bottom: 0;
        display: flex;
        padding: 5px 15px 10px 15px;
        width: calc(100% / 6);
        text-align: center;
        border-right: 2px solid white;
        justify-content: center;
        a {
          line-height: 0;
          font-size: 7px;
        }
        i {
          margin-right: 0 !important;
          width: 100%;
          font-size: 15px !important;
          &:after {
            content: "";
            clear: both;
            display: inline-flex;
            width: 100%;
          }
        }
        &:nth-child(2), &:nth-child(5) {
          display: flex;
          justify-content: center;
        }
      }
      */
    }
  }
  footer .footer-menu {
    width: 100%;
    justify-content: center;
  }
  .page-article-box__img img {
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
  .page-article-box {
    flex-wrap: wrap;
    h6 {
      padding-left: 0;
    }
    p {
      font-size: 17px;
      padding: 15px 0;
    }
    .page-article-box__desc {
      order: 1;
    }
  }
  .image-box__description {
    margin-bottom: 20px;
  }
  .page-article-box__img {
    padding-right: 0;
    img {
      width: 100%;
      height: auto;
    }
  }
  .footer-mobile {
    span {
      br {
        display: block;
      }
    }
  }
  footer .footer-menu__li a i {
    position: relative;
    left: 58px;
  }
  .image-box .image-box__title h5 {
    font-size: 17px;
    line-height: 22px;
  }
  .page-two-bg {
    overflow: hidden;
    img {
      height: 159px;
      width: auto;
    }
  }
  /* .image-box {
    max-height: 110px;
  } */
  footer .footer-menu {
    display: none;
  }
}

@media screen and (min-width: 376px) and (max-width: 414px) {
  .slick-slider-logo .slick-prev:before, .slick-slider-logo .slick-next:before {
    position: relative;
    top: 10px;
  }
}

@media screen and (min-width: 120px) and (max-width: 414px) {
  .footer-mobile-3 .footer-menu__i {
    display:none;
  }
  .mm-btn_prev:before {
    left: 20px;
  }
  /* .image-box {
    max-height: 110px;
  } */
  header nav .navbar-brand {
    order: 2;
  }
  .right-navigation {
    order: 1;
    width: 100%;
  }
  .right-navigation__top-button {
    width: 100%;
    position: relative;
    justify-content: center;
    &:after {
      position: absolute;
      content: "";
      display: inline-flex;
      width: 100%;
      clear: both;
    }
  }
  .right-navigation form {
    top: 16px;
    margin-right: 12px;
  }
  .right-navigation__form {
    justify-content: flex-end;
    margin-left: (-15px) !important;
  }
  .footer-mobile {
    text-align: center;
    margin-bottom: 20px;
  }
  .herold-navbar {
    flex-wrap: wrap;
  }
  header nav .navbar-brand {
    padding-top: 0;
    width: auto;
    margin-top: -50px;
  }
  .bg-orange-button, .bg-grey-button {
    width: 100%;
  }
  .bg-orange-button {
    flex-grow: 1;
    flex-basis: 40%;
  }
  .bg-grey-button {
    flex-grow: 3;
  }
  .right-navigation__top-button {
    margin-bottom: 0;
  }
  .icon-menu {
    top: 31px;
    margin-right: 5px;
    margin-left: 5px;
  }
  /* .container-image-box {
    padding: 0;
    .row {
      margin-right: 0;
      margin-left: 0;
    }
  }
  .col-image-box {
  -ms-flex: 0 0 50%;
flex: 0 0 100%;
max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 10px;
    &:nth-child(1), &:nth-child(2), &:nth-child(4) {
      padding-right: 10px;
    }
    &:nth-child(3) {
      padding-right: 0;
    }
    .image-box {
      max-height: 150px;
      max-width: 100%;
    }
  }
  
  
  .col-image-box {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }*/
  .slick-slider-item__description {
    padding-left: 10px;
    h2 {
      font-size: 23px;
      line-height: 22px;
      margin-bottom: 5px;
    }
    p {
      font-size: 17px;
      line-height: 21px;
      margin-bottom: 5px;
      max-height: 40px;
    }
  }
  .slick-dots {
    display: none !important;
  }
  .right-fixed-menu {
    bottom: 0;
    left: 0;
    top: auto;
    display: flex;
    width: 100%;
    ul {
      display: inline-flex;
      width: 100%;
      li {
        border-radius: 0;
        right: auto;
        margin-bottom: 0;
        display: flex;
        padding: 5px 15px 10px 15px;
        /* width: calc(100% / 6);
        text-align: center;
        border-right: 2px solid white;
        justify-content: center; */
        a {
          line-height: 0;
          /* font-size: 7px; */
          font-size: 15px;
        }
        i {
          font-size: 20px !important;
        }
        /* i {
          margin-right: 0 !important;
          width: 100%;
          font-size: 15px !important;
          &:after {
            content: "";
            clear: both;
            display: inline-flex;
            width: 100%;
          }
        }
        &:nth-child(2), &:nth-child(5) {
          display: flex;
          justify-content: center;
        }
        */
      }
    }
  }
  footer .footer-menu {
    width: 100%;
    justify-content: center;
  }
  .page-article-box {
    flex-wrap: wrap;
    h6 {
      padding-left: 0;
    }
    p {
      font-size: 17px;
      padding: 15px 0;
    }
    .page-article-box__desc {
      order: 1;
    }
  }
  .image-box__description {
    margin-bottom: 20px;
  }
  .page-article-box__img {
    padding-right: 0;
    img {
      width: 100%;
      height: auto;
    }
  }
  .mm-listitem {
    font-size: 20px;
    line-height: 45px;
  }
  .scroll-top {
    right: 10px;
    bottom: 55px;
  }
  .scroll-top a i {
    font-size: 45px;
  }
  .slick-arrow-static {
    display: none;
  }
  .footer-mobile {
    span {
      br {
        display: block;
      }
    }
    br {
      display: none;
    }
  }
  footer .footer-menu__li a i {
    position: relative;
    left: 58px;
  }
  .image-box .image-box__title h5 {
    font-size: 17px;
    line-height: 22px;
  }
  .page-two-bg {
    overflow: hidden;
    img {
      height: 108px;
      width: auto;
    }
  }
  .slick-slider-logo .slick-next, .slick-slider-logo .slick-prev {
    top: 15px;
  }
  footer .footer-menu {
    display: none;
  }
  footer .footer-menu-mobile {
    display: block;
  }
  .footer-menu-mobile {
    margin-right: -10px;
    margin-left: -10px;
    &__ul {
      text-align: center;
      display: inline-flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    &__li {
      width: 25%;
      margin-bottom: 7px;
      a {
        color: #d37016;
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
      }
      &:nth-child(5), &:nth-child(6) {
        width: 33%;
      }
      /*&:last-child {
        width: 100%;
        a {
          font-size: 55px;
          font-weight: 400;
          color: black;
          opacity: 0.2;
          margin-top: 10px;
        }
      }*/
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .slick-slider-logo .slick-prev:before, .slick-slider-logo .slick-next:before {
    position: relative;
    top: 5px;
  }
}