a:active, a:focus {
  outline: none;
}

input, textarea {
  outline: none;
}

input:active, textarea:active {
  outline: none;
}

.right-fixed-menu {
  position: fixed;
  right: 0;
  top: 260px;
  z-index: 1;
  ul {
    li {
      width: 165px;
      border-radius: 12px 0 0 12px;
      background-color: #565656;
      position: relative;
      margin-right: -120px;
      margin-bottom: 4px;
      padding: 5px 11px;
      transition: all 0.7s;
      right: 0;
      &:hover {
        background-color: $orange-text;
      }
      &.active {
        right: 120px;
        z-index: 10;
        transition: all 0.7s;
        a {
          text-decoration: none;
        }
      }
      a {
        color: white;
        font-size: 16px;
        line-height: 20px;
        font-family: "KorolevCondensed", sans-serif;
        font-weight: 700;
        position: relative;
        top: -3px;
        i {
          margin-right: 13px;
          font-size: 27px;
          position: relative;
          top: 5px;
        }
      }
      &:nth-child(2), &:nth-child(5) {
        display: none;
      }
    }
  }
}

.container.static-header-wrapper img {
    height:auto;
}

.box-bread-crumbs {
  display: flex;
  margin-top: 12px;
  &__title {
    margin-right: 5px;
    p {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.bread-crumbs {
  display: flex;
  li {
    display: inline-flex;
    color: $orange-text;
    font-size: 14px;
    line-height: 16px;
    margin-right: 5px;
    padding-right: 10px;
    position: relative;
    a {
      color: #b4b4b4;
      transition: 0.3s;
      &:hover {
        opacity: 0.6;
      }
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    &:after {
      content: ">";
      position: absolute;
      right: 0;
      top: 0;
      color: #b4b4b4;
    }
  }
}

.slick-slider {
  width: 100%;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 100%;
  flex-wrap: wrap;
  position: relative;
  .slick-dots {
    position: absolute;
    bottom: 20px;
    left:0;
    right: 0;
    top: auto;
    margin:0 auto;
    width: 100%;
    max-width: 1140px;
    text-align: right;
    z-index: 4;
    padding-right: 15px;
    li {
      margin: 0 7px;
      &:last-child {
        margin-right: 0;
      }
      button {
        &:before {
          font-size: 59px;
        }
      }
    }
  }
  .slick-slider-item {
    display: flex;
    flex: 1 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    img {
      display: block;
      margin: auto;
      height: auto;
    }
  }
  .slick-next {
    right: 0;
  }
  .slick-previous {
    left: 0;
    z-index: 1;
  }
}

.slick-slider-item {
  position: relative;
  text-align: center;
  &__description {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 130px;
    h2 {
      font-size: 66px;
      line-height: 61px;
      font-weight: 700;
      max-width: 450px;
      font-family: "KorolevBold", sans-serif;
      margin-bottom: 10px;
    }
    p {
      font-size: 20px;
      line-height: 26px;
      max-height: 50px;
      max-width: 535px;
      overflow: hidden;
      font-weight: 700;
      margin-bottom: 20px;
      color: white;
    }
    .bg-orange-button {
      background-color: #d37016;
      color: white;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      border-radius: 4px;
      border: 1px solid white;
      text-align: center;
      width: 150px;
      i {
        margin-left: 5px;
        opacity: 0.35;
      }
    }
  }
}

.slick-slider-row {
  position: relative;
  height: 100%;
  .slick-slider {
    min-height: 0;
    min-width: 0;
  }
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.container-next-prev {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 52%;
  &__arrows {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 15px;
    .prev, .next {
      font-size: 60px;
      color: #ffffff;
      opacity: 0.85;
      cursor: pointer;
      transition: 0.2s;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.slick-arrow-static {
  font-size: 60px;
  line-height: 10px;
  width: 40px;
  position: absolute;
  bottom: 0;
  left: calc(50% - 20px);
  color: #565656;
  opacity: 0.75;
  display:none;
}

#page {
    width:100%;
    overflow:hidden;
}

.main-content {
    overflow:hidden;
              a {
                color: $orange-text;
                text-decoration: underline;
              }
    #col-main-content{
        margin-top:24px;
        .frame { 
            margin-bottom: 32px; 
            h1, h2, h3, h4 {
              font-size: 40px;
              line-height: 50px;
              color: $orange-text;
              text-transform:uppercase;
              font-family: "KorolevBold", sans-serif;
              margin-top: 0;
              margin-bottom: 8px;
            }
            h3 {
              font-size: 30px;
              line-height: 40px;
              margin-bottom: 6px;
            }
            h4 {
              font-size: 24px;
              line-height: 32px;
              margin-bottom: 5px;
            }
            .header-mit-subline {
                margin-bottom: 15px;
                h2.sub-line,
                h3.sub-line,
                h4.sub-line,
                h5.sub-line,
                h6.sub-line {
                    color:#565656;
                    margin-bottom: 0px;
                    text-transform: none;
                }
                h2.sub-line, h3.sub-line {
                    font-size: 32px;
                    line-height: 40px;
                }
                h4.sub-line {
                    font-size: 26px;
                    line-height: 32px;
                }
                h5.sub-line {
                    font-size: 22px;
                    line-height: 28px;
                }
            }
            .header-mit-subline h1 {
                margin-bottom: 0px;
            }
            .header-mit-subline h2 {
                margin-bottom: 0px;
            }
            .header-mit-subline h3 {
                margin-bottom: 0px;
            }
            p {
                margin:0 0 15px 0;
                &:last-child {
                    margin:0 0 0 0;
                }
            }
            p, ul li, ol li {
              a {
                color: $orange-text;
                text-decoration: underline;
              }
              span {
                font-weight: 700;
              }
            }
            &__mb-text {
              margin-bottom: 3px;
            }
      }
      .frame.frame-100 {
        background-color: #ededed;
        .ce-gallery {
            margin-bottom:0;
        }
        .ce-bodytext {
            padding:20px;
        }
        @media screen and (max-width: 480px) {
           .ce-gallery {
            margin:0 0 15px 0;
            } 
        }
      }
  }
  #col-main-content.col-main-content-home {
      margin-top:40px;
  }
}

.image-box {
  position: relative;
  height: 100%;
  /* max-height: 255px; */
  width: 100%;
  overflow: hidden;
  transition: all 0.2s;
  &__description {
    font-size: 16px;
    line-height: 20px;
    color: #565656;
    margin-top: 10px;
  }
  .image-box__white-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0;
    z-index: 1;
    transition: all 0.2s ease;
  }
  &:hover {
    .image-box__title {
      h5 {
        color: $orange-text;
        transition: all 0.2s;
      }
    }
    .image-box__white-bg {
      opacity: 0.5;
    }
  }
  img {
    height: auto;
    width: 100%;
  }
  .image-box__title {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 2;
    h5 {
      text-align: center;
      width: 100%;
      color: white;
      font-size: 29px;
      font-weight: 700;
    }
  }
}

.slick-slider-logo {
  padding: 0 30px;
  margin-top: 76px;
  margin-bottom: 60px;
  position: relative;
  &__item {
    text-align: center;
    padding: 0 10px;
    div {
      display: inline-flex;
      align-content: center;
    }
    img {
      height: 100%;
      max-height: 40px;
      width: 100%;
    }
  }
  .slick-next, .slick-prev {
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    top: calc(50% - 2px);
    top:8px;
  }
  .slick-next {
    right: 0;
    &:before {
      font-family: "Font Awesome 5 Pro";
      content: "\f105";
      color: #565656;
      font-size: 60px;
      line-height: 10px;
    }
  }
  .slick-prev {
    left: 0;
    &:before {
      font-family: "Font Awesome 5 Pro";
      content: "\f104";
      color: #565656;
      font-size: 60px;
      line-height: 10px;
    }
  }
}

.page-two-bg {
  img {
    width: 100%;
    height: 100%;
  }
}

.page-article-box {
  display: flex;
  background-color: #ededed;
  margin-bottom: 25px;
  &__img {
    padding-left: 0;
    overflow: hidden;
    img {
      height: 100%;
      max-height: 345px;
      width: auto;
    }
  }
  p {
    font-size: 19px;
    line-height: 26px;
    padding: 15px;
    font-weight: 300;
  }
  h6 {
    font-size: 24px;
    line-height: 30px;
    color: $orange-text;
    padding-left: 15px;
    margin-top: 15px;
    font-weight: 700;
    span {
      font-size: 20px;
      line-height: 26px;
      color: #565656;
    }
  }
}

.photo-box {
  margin: 10px 0 30px 0;
  display: flex;
  &__img {
    width: 88px;
    height: 118px;
    overflow: hidden;
    margin-right: 24px;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.mm-page__blocker {
  background: rgba(3, 2, 1, 0.7);
  &:after {
    content: "\f00d";
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 80px;
    height: 50px;
    background-color: $orange-text;
    color: white;
    line-height: 55px;
    text-align: center;
    border-radius: 0 5px 0 0;
    transition: all 0.2s;
  }
}
/* 
.slick-slider-logo .slick-next, .slick-slider-logo .slick-prev {
  height: 160%;
  width: 25px;
  top: 28px;
}
*/
.page-two-bg {
  position: relative;
}

.grid-elements-4-cols-no-width {
    margin-top:24px;
    margin-bottom:24px;
}

.grid-elements-4-cols {
    .grid-elements-4-cols-1, .grid-elements-4-cols-2, .grid-elements-4-cols-3, .grid-elements-4-cols-4 {
        
    }
    @media screen and (max-width: 991px) {
        margin-right: -5px;
        margin-left: -5px;
        .grid-elements-4-cols-1, .grid-elements-4-cols-2, .grid-elements-4-cols-3, .grid-elements-4-cols-4 {
            padding-right: 5px;
            padding-left: 5px;
        }
    }
    /* @media screen and (min-width: 415px) and (max-width: 575px) {
        margin-right: -5px;
        margin-left: -5px;
        .grid-elements-4-cols-1, .grid-elements-4-cols-2, .grid-elements-4-cols-3, .grid-elements-4-cols-4 {
            padding-right: 5px;
            padding-left: 5px;
        }
    } */
    @media screen and (max-width: 575px) {
        .grid-elements-4-cols-1, .grid-elements-4-cols-2, .grid-elements-4-cols-3, .grid-elements-4-cols-4 {
            margin-bottom:10px;
        }
    }
    
    @media screen and (min-width: 120px) and (max-width: 414px) {
        .grid-elements-4-cols-1, .grid-elements-4-cols-2, .grid-elements-4-cols-3, .grid-elements-4-cols-4 {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            padding-right: 0px;
            padding-left: 0px;
        }
        
    }
}



.grid-elements-2-cols-6-6 {
    .grid-elements-2-cols-left-6-6, .grid-elements-2-cols-right-6-6 {
        
    }
    @media screen and (max-width: 991px) {
        margin-right: -5px;
        margin-left: -5px;
        .grid-elements-2-cols-left-6-6, .grid-elements-2-cols-right-6-6 {
            padding-right: 5px;
            padding-left: 5px;
        }
    }
    @media screen and (max-width: 575px) {
        .grid-elements-2-cols-left-6-6, .grid-elements-2-cols-right-6-6 {
            margin-bottom:10px;
        }
    }
    
    @media screen and (min-width: 120px) and (max-width: 414px) {
        .grid-elements-2-cols-left-6-6, .grid-elements-2-cols-right-6-6 {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            padding-right: 0px;
            padding-left: 0px;
        }
        
    }
}
