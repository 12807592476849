header {
  img.logo {
    width: 206px;
    height: 70px;
  }
  background-color: #ededed;
  nav {
    width: 100%;
    padding-top: 0 !important;
    .collapse.navbar-collapse {
      position: relative;
      top: 11px;
    }
    .navbar-nav {
      position: relative;
    }
    .navbar-brand {
      padding-top: 19px;
      z-index: 1;
    }
    li {
      &.not-dropdown {
        a {
          position: relative;
          top: 1px;
        }
      }
      .dropdown-menu {
        border-radius: 0 0 8px 8px;
        background-color: $orange-text;
        border-top: 0;
        position: absolute;
        margin-top: -3px;
        a {
          text-transform: uppercase;
          &:hover, &:focus {
            background-color: transparent;
            &.dropdown-item {
              opacity: 0.5;
            }
          }
          &.dropdown-item {
            color: white;
            font-size: 15px;
            line-height: 24px;
            transition: all 0.3s;
            font-family: "KorolevCondensed", sans-serif;
            font-weight: 300;
            padding: 0 9px;
          }
        }
      }
      a {
        font-size: 15px;
        line-height: 30px;
        text-transform: uppercase;
        font-weight: 700;
        font-family: "KorolevCondensed", sans-serif;
      }
    }
  }
  .sf-menu {
    margin-bottom: 0;
    li {
      background: none;
      a {
        &.current-a {
          &:hover {
            color: $orange-text;
            text-decoration: none;
          }
        }
        &.active {
          color: $orange-text;
          text-decoration: none;
        }
      }
      &:hover {
        a {
          text-decoration: none;
        }
      }
      &.current {
        &.sfHover {
          background: none;
        }
      }
      ul {
        border-radius: 0 0 8px 8px;
        background-color: $orange-text;
        padding: 10px 10px;
        li {
          &:hover {
            background: none;
          }
          a {
            color: white;
            font-size: 15px;
            line-height: 24px;
            transition: all 0.3s;
            font-family: "KorolevCondensed", sans-serif;
            font-weight: 300;
            padding: 0 9px;
            &:hover {
              text-decoration: none;
              opacity: 0.5;
            }
          }
        }
      }
    }
    a {
      font-size: 15px;
      line-height: 19px;
      text-transform: uppercase;
      font-weight: 700;
      font-family: "KorolevCondensed", sans-serif;
      color: #565656;
      border: 0;
    }
    ul {
      top: 39px;
    }
  }
  ul.sf-menu .sf-with-ul {
    &:after {
      content: "\f107";
      font-family: "Font Awesome 5 Pro";
      border: none;
      vertical-align: 0;
      top: 13px;
      right: 25px;
      font-weight: 300;
    }
  }
  .sf-arrows .sf-with-ul {
    padding-right: 2.2em;
  }
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
  color: $orange-text;
}

.right-navigation {
  display: flex;
  position: relative;
  align-content: flex-end;
  flex-direction: column;
  .navbar-nav .nav-link {
    padding-right: 15px;
    padding-left: 15px;
  }
  &__form {
    margin-bottom: 20px;
    margin-right: 0;
  }
  &__top-button {
    display: flex;
    .bg-orange-button, .bg-grey-button {
      height: 41px;
    }
  }
  form, div.form-inline {
    margin-left: 0px;
    margin-right: 0px;
    position: relative;
    top: 3px;
    button {
      padding-left:30px;
      padding-right:0;
      i {
        font-size: 27px;
        color: #d37016;
      }
    }
  }
  .quick-search {
    position:absolute;
    display:none;
    top:45px;
    bottom:auto;
    right: 0;
    left: auto;
    form {
      margin-left: 0px;
      margin-right: 0px;
      position: relative;
      top: 0px;
      #tx-indexedsearch-searchbox-button-submit {
        display:none;
      }
    }
  }
  .quick-search.visible {
    display:block;
  }
}

.herold-navbar {
  justify-content: space-between;
  form, div.form-inline {
    margin-left: 0px;
    margin-right: 0px;
    position: relative;
    top: 3px;
    button {
      padding-left:30px;
      padding-right:0;
      i {
        font-size: 27px;
        color: #d37016;
      }
    }
  }
  .quick-search {
    position:absolute;
    display:none;
    top:75px;
    bottom:auto;
    right: 125px;
    left: auto;
    z-index: 2;
    form {
      margin-left: 0px;
      margin-right: 0px;
      position: relative;
      top: 0px;
      #tx-indexedsearch-searchbox-button-submit {
        display:none;
      }
    }
  }
  .quick-search.visible {
    display:block;
  }
}

.bg-orange-button {
  background-color: #d37016;
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  span {
    text-transform: none;
    font-size: 16px;
  }
}

.bg-grey-button {
  background-color: #565656;
  font-size: 12px;
  padding-left: 25px;
  padding-right: 25px;
}

.bg-orange-button, .bg-grey-button {
  border-radius: 0;
  border: none;
  font-family: "KorolevCondensed", sans-serif;
}

.icon-menu {
  width: auto;
  position: relative;
  top: 3px;
  margin-left:30px;
  i {
    font-size: 35px;
    position: relative;
    top: 3px;
    /* right: 10px; */
    color: $orange-text;
    transition: all 0.2s;
    &:hover {
      opacity: 0.6;
    }
  }
  .icon-menu__line {
    position: relative;
    display: flex;
    margin-bottom: 10px;
    top: 10px;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      height: 4px;
      background-color: $orange-text;
    }
  }
}

.mm-menu {
  background: #565656;
}

.mm-listitem {
  font-size: 33px;
  line-height: 52px;
  a {
    color: #b4b4b4 !important;
  }
  a.active {
    background-color: #d37016 !important;
    color: #fff !important;
  }
  span {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 70px;
      height: 100%;
      border-right: 1px solid #b4b4b4;
    }
  }
  &:after {
    left: 0;
    border-color: #b4b4b4;
  }
}

.mm-listitem > a, .mm-listitem > span {
  color: #b4b4b4;
  text-transform: uppercase;
}

.mm-navbar {
  height: auto;
  padding: 10px 40px;
  border-color: #b4b4b4;
  &__title {
    font-size: 33px;
    line-height: 45px;
  }
}

a.mm-navbar__title {
  color: white !important;
}

.mm-panels > .mm-panel > .mm-listview:first-child, .mm-panels > .mm-panel > .mm-navbar + .mm-listview {
  margin-top: 27px;
}

.mm-menu .mm-listview .mm-btn_next:after {
  border-color: #b4b4b4;
  right: 31px;
}

.mm-menu .mm-btn:after, .mm-menu .mm-btn:before {
  border-color: #b4b4b4;
}

.mm-btn {
  height: 87px;
}

.mm-btn_next:after {
  content: "\f0da";
  border: none;
  font-size: 45px;
  font-family: "Font Awesome 5 Pro";
  transform: rotate(0deg);
  width: auto;
  height: auto;
  font-weight: 900;
}

.mm-btn_prev:before {
  left: 25px;
  top: 30px;
  content: "\f0d9";
  font-family: "Font Awesome 5 Pro";
  border: none;
  font-size: 45px;
  color: #b4b4b4;
  transform: rotate(0deg);
  width: auto;
  height: auto;
  font-weight: 900;
}

.mm-menu .mm-listview .mm-btn_next:after {
  right: 25px;
  top: 10px;
}


@media screen and (max-width: 991px) {
  .herold-navbar form, .right-navigation form, .right-navigation div.form-inline {
    margin-top:0px !important;
    margin-bottom:0px !important;
  }
  .right-navigation .quick-search,
  .herold-navbar .quick-search {
    top: 75px;
    right: 118px;
  }
}

@media screen and (max-width: 575px) {
  .herold-navbar form, .right-navigation form, .right-navigation div.form-inline {
    top:23px !important;
  }
  .right-navigation .quick-search,
  .herold-navbar .quick-search {
    top: 48px;
  }
}
