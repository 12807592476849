@font-face {
  font-family: "KorolevCondensed";
  src: url("../fonts/KorolevMediumCondensed.eot");
  src: url("../fonts/KorolevMediumCondensed.eot") format("embedded-opentype"),
  url('../fonts/KorolevMediumCondensed.woff2') format('woff2'),
  url('../fonts/KorolevMediumCondensed.woff') format('woff'),
  url('../fonts/KorolevMediumCondensed.ttf') format('truetype'),
  url('../fonts/KorolevMediumCondensed.svg#KorolevMediumCondensed') format('svg') {}
  font-weight: 300;
}


@font-face {
  font-family: "KorolevCondensed";
  src: url("../fonts/KorolevBoldCondensed.eot");
  src: url("../fonts/KorolevBoldCondensed.eot") format("embedded-opentype"),
  url('../fonts/KorolevBoldCondensed.woff2') format('woff2'),
  url('../fonts/KorolevBoldCondensed.woff') format('woff'),
  url('../fonts/KorolevBoldCondensed.ttf') format('truetype'),
  url('../fonts/KorolevBoldCondensed.svg#KorolevBoldCondensed') format('svg') {}
  font-weight: 700;
}


@font-face {
  font-family: "KorolevBold";
  src: url("../fonts/KorolevBold.eot");
  src: url("../fonts/KorolevBold.eot") format("embedded-opentype"),
  url('../fonts/KorolevBold.woff2') format('woff2'),
  url('../fonts/KorolevBold.woff') format('woff'),
  url('../fonts/KorolevBold.ttf') format('truetype'),
  url('../fonts/KorolevBold.svg#KorolevBold') format('svg') {}
}

/* LZ */

%fontawesome {
content: "\f101";
font-family: 'Font Awesome\ 5 Pro';
font-weight: 300;
-moz-osx-font-smoothing: grayscale;
-webkit-font-smoothing: antialiased;
display: inline-block;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1;
}