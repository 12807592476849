footer {
  background-color: #ededed;
  padding: 25px 0 45px 0;
  .footer-mobile-2 {
    /*padding-left:0;
    padding-right: 0;*/
  }
  .footer-mobile-4 {
    p {
      font-size: 16px;
      line-height: 22px;
      a {
        color: #d37016;
        transition: all 0.3s;
        &:hover {
          opacity: 0.5;
          text-decoration: underline;
          &:after { 
            text-decoration: none;
            display: inline-block;
          }
        }
        &:after {
          content: "\f101";
          font-family: "Font Awesome 5 Pro";
          padding-left: 4px;
          font-weight: 100;
        }
      }
    }
  }
  h4 {
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    color: #565656;
  }
  .footer-menu {
    display: flex;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    font-family: "KorolevCondensed", sans-serif;
    &__ul {
      &:first-child {
        margin-right: 30px;
      }
    }
    &__li {
      margin-bottom: 3px;
      color: $orange-text;
      a {
        color: $orange-text;
        transition: all 0.3s;
        &:hover {
          text-decoration: underline;
        }
        i {
          font-size: 55px;
          font-weight: 400;
          color: black;
          opacity: 0.2;
          margin-top: 10px;
        }
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
  .footer-menu__i {
    i {
      font-size: 55px;
      font-weight: 400;
      color: black;
      opacity: 0.2;
      margin-top: 10px;
    }
    &:hover {
      opacity: 0.5;
    }
  }
  p {
    a {
      font-size: 20px;
      line-height: 26px;
      font-weight: 300;
      color: #565656;
      transition: all 0.3s;
      &:hover {
        opacity: 0.5;
      }
    }
    span {
      font-weight: 700;
    }
  }
}

footer .footer-menu-mobile {
  display: none;
}

.scroll-top {
  position: fixed;
  right: 50px;
  bottom: 50px;
  display: none;
  z-index: 4;
  a {
    color: black;
    opacity: 0.2;
    transition: all 0.3s;
    display: inline-block;
    &:hover {
      opacity: 0.5;
    }
    i {
      font-size: 55px;
    }
  }
}

@media screen and (max-width: 991px) {
  footer .footer-menu {
    display: block;
  }
  footer .footer-menu__ul:first-child {
    margin-right: 0px;
  }
  footer .footer-menu__ul {
    width:100%;
  }
}

@media screen and (max-width: 767px) {
  footer .footer-menu {
    display: flex;
  }
  footer .footer-menu__ul:first-child {
    margin-right: 30px;
  }
  footer .footer-menu__ul {
    width:auto;
  }
  footer {
    .footer-mobile-4 {
      ul {
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  footer {
    .footer-mobile-3 { margin: 0; }
    .footer-mobile-4 {
      .footer-menu-mobile { margin-top: 20px; }
      a {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
      }
    }
  }
}