* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

html {
  height: 100%;
  width: 100%;
}

body {
  font-family: "KorolevCondensed", sans-serif;
  line-height: $global-line-height;
  font-size: 100%;
  background-color: $global-background;
  min-width: 320px;
  position: relative;
  height: 100%;
  width: 100%;
}

#menu {
    display: none;
}

#menu.mm-menu_offcanvas.mm-menu_opened {
    display: block;
}

ul, p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-family: "KorolevCondensed", sans-serif;
}

p, ul li, ol li {
  font-size: 20px;
  line-height: 26px;
  font-weight: 300;
  color: #565656;
}

li {
  list-style-type: none;
}

.ce-bodytext ul,
.ce-bodytext ol {
  padding:0 0 0 25px;
  margin:15px 0;
  &:last-child { margin:15px 0 0 0; }
} 

.ce-bodytext ul li,
.ce-bodytext ol li{
  list-style-type: circle;
} 

button {
  cursor: pointer;
  font-family: "KorolevCondensed", sans-serif;
  &:focus {
    outline: 0;
  }
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

a {
  text-decoration: none;
}

input:invalid {
  box-shadow: none;
}

input:-moz-submit-invalid {
  box-shadow: none;
}

input:-moz-ui-invalid {
  box-shadow: none;
}

input::-ms-clear {
  display: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

input {
  -webkit-appearance: none;
  display: inline-block;
  &[type="color"],
  &[type="date"],
  &[type="datetime"],
  &[type="datetime-local"],
  &[type="email"],
  &[type="month"],
  &[type="number"],
  &[type="password"],
  &[type="search"],
  &[type="tel"],
  &[type="text"],
  &[type="time"],
  &[type="url"],
  &[type="week"] {
    font-size: 16px;
    border: 1px solid black;
  }
}

input::-webkit-input-placeholder {
  opacity: 1;
  text-overflow: ellipsis;
}

input:-moz-placeholder {
  opacity: 1;
  text-overflow: ellipsis;
}

input::-moz-placeholder {
  opacity: 1;
  text-overflow: ellipsis;
}

input:-ms-input-placeholder {
  opacity: 1;
  text-overflow: ellipsis;
}

img {
  width: 100%;
  display: inline-block;
}

.clear:after {
  content: "";
  display: block;
  clear: both;
}